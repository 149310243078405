<script setup lang="ts">
interface Props {
	id?: string
	type?: 'checkbox' | 'radio'
	name?: string
	label?: string
	modelValue?: string | boolean | number
	checked?: boolean
	optional?: boolean
	severity?: 'success' | 'error'
	size?: 'small'
	icon?: string
	disabled?: boolean
	passwordStrength?: boolean
	button?: boolean
	pill?: boolean
	value?: string | number
	errorMessage?: string
}
const props = withDefaults(defineProps<Props>(), {
	type: 'checkbox',
});

const emit = defineEmits(['update:modelValue']);

const innerValue = ref(props.modelValue);

watch(() => props.modelValue, (value) => {
	innerValue.value = value;
});

const id = props.id ?? useId();
</script>

<template>
	<div class="form-check-container">
		<div
			class="form-check"
			:class="[{
				[`form-check--${props.severity}`]: props.severity,
				'form-check:sm': props.size === 'small',
				'form-check--button': props.button,
				'form-check--pill': props.pill,
				'form-check--password-strength': props.passwordStrength,
				'form-check--invalid': errorMessage,
			}]"
		>
			<input
				:type="props.type"
				:name="props.name"
				:disabled="props.disabled"
				:value="props.value"
				:checked="props.checked || (props.type === 'radio' && innerValue === props.value)"
				:id="id"
				v-model="innerValue"
				@change="emit('update:modelValue', innerValue)"
			/>
			<label :for="id">
				<Icon :name="props.icon" :size="props.pill ? 16 : undefined" v-if="props.icon && (props.button || props.pill)" />
				<span class="form-check__text" v-if="props.label">
					<span v-html="props.label"></span>
					<span class="form-check__optional" v-if="props.optional">{{ $t('props.optional') }}</span>
				</span>
			</label>
		</div>

		<div class="form-check-response" v-if="errorMessage">
			<div class="form-check-response__item form-check-response__item--error">
				<Icon name="cancel" size="14" />
				<span>{{ errorMessage }}</span>
			</div>
		</div>
	</div>
</template>
